import { css } from 'styled-components'

const display = css`
  .d-inline-block {
    display: inline-block;
  }

  .d-block {
    display: block;
  }
`

export default display
