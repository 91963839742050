import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

const Container = styled.div<{ tight?: boolean }>`
  display: grid;
  grid-gap: var(--gap);
  max-width: 1380px;
  padding: 0 3.2rem;
  margin: 0 auto;

  ${up('sm')} {
    padding: 0 2rem;
  }

  ${({ tight }) =>
    tight &&
    css`
      max-width: 100.8rem;
    `}
`

export default Container
