import { css } from 'styled-components'

const nprogress = css`
  #nprogress {
    .bar {
      background: var(--brand-red);
    }

    .peg {
      box-shadow: 0 0 10px var(--brand-red), 0 0 5px var(--brand-red);
    }

    .spinner-icon {
      border-top-color: var(--brand-red);
      border-left-color: var(--brand-red);
    }
  }
`

export default nprogress
