import Link from 'next/link'
import styled, { css } from 'styled-components'
import Container from '@components/styled/Container'
import Text from '@components/Text'
import TextLink from '@components/Link'
import pagesTree from '@data/pagesTree'
import { backgroundColor, glossy } from './styles'

const Component = styled.div`
  display: none;
  position: fixed;
  z-index: 90;
  top: var(--navbar-height);
  height: calc(100vh - var(--navbar-height));
  width: 100%;
  padding: 4rem 0 0;
  overflow: hidden;
  ${backgroundColor}
  ${glossy}

  @media (max-width: 1100px) {
    display: block;
  }
`

const Menu = styled.ul`
  list-style: none;
  margin: 0 0 4.8rem;
  padding: 0;
`

const MenuItem = styled.li<{ $highlight: boolean }>`
  color: var(--brand-dark-blue);
  font-family: Campton;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin: 0 0 3.2rem;

  &:last-child {
    margin: 0;
  }

  ${(props) =>
    props.$highlight &&
    css`
      position: relative;
      color: var(--brand-red);

      &:after {
        content: '';
        display: inline-block;
        right: 0;
        top: 50%;
        transform: translateX(-50%);
        width: 0.8rem;
        height: 0.8rem;
        background: var(--brand-red);
        border-radius: 100%;
        margin-left: 2rem;
        vertical-align: middle;
      }
    `}
`

// TODO: Fix any
type IProps = {
  setMobileMenuActive: (e: any) => void
}

// TODO: to do code splitting
const MobileMenu: React.FC<IProps> = ({ setMobileMenuActive }) => (
  <Component>
    <Container>
      <Menu>
        {pagesTree.map((item) => (
          <MenuItem
            $highlight={item.props.highlight as boolean}
            key={item.href}
          >
            <Link href={item.href} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => setMobileMenuActive((p: any) => !p)}
                onKeyDown={() => setMobileMenuActive((p: any) => !p)}
              >
                {item.title}.
              </a>
            </Link>
          </MenuItem>
        ))}
      </Menu>
      <Text className="mb-8" size="small">
        Get in touch
      </Text>
      <TextLink size="big" href="mailto:hello@empressia.co">
        hello@empressia.co
      </TextLink>
    </Container>
  </Component>
)

export default MobileMenu
