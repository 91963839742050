import mitt from 'mitt'

export const emitter = mitt()

/**
 * Callback with setTimeout factory
 *
 * @param fn callback
 * @param timeout second argument of setTimeout
 */
export const withTimeoutFactory = <T extends any[], R>(
  fn: (...args: T) => R,
  timeout = 0,
  completeFn: () => void,
) => {
  if (document.readyState === 'complete') {
    setTimeout(fn, timeout)
  } else {
    completeFn()
  }
}

/**
 * Fire callback after window.onload
 *
 * @param callback
 */
export const windowLoad = (callback: (event?: Event) => void) => {
  if (document.readyState === 'complete') {
    callback()
  } else {
    window.addEventListener('load', callback)
  }
}

/**
 * Fire callback after window.onload and setTimeout
 *
 * @param callback
 * @param timeout second argument of setTimeout
 */
export const windowLoadWithTimetout = (
  callback: (event?: Event) => void,
  timeout = 0,
) => {
  withTimeoutFactory(callback, timeout, () => {
    emitter.on('WINDOW_LOAD', () => {
      setTimeout(callback, timeout)
    })
  })
}

/**
 * Fire callback after window.onload and setTimeout
 *
 * @param fn callback
 * @param timeout second argument of setTimeout
 */
export const documentLoadWithTimetout = <T extends any[], R>(
  fn: (...args: T) => R,
  timeout = 0,
) => {
  withTimeoutFactory(fn, timeout, () => {
    document.addEventListener('DOMContentLoaded', () => {
      setTimeout(fn, timeout)
    })
  })
}
