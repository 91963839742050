import { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import cx from 'classnames'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

export interface ButtonProps {
  text: string
  dot?: boolean
  forceHover?: boolean
  theme?:
    | 'red'
    | 'red-outline'
    | 'white'
    | 'white-outline'
    | 'gray-to-red-outline'
}

const ButtonFiller = styled.div`
  background: var(--btn-bg-hover);
  position: absolute;
  width: 150%;
  height: 200%;
  border-radius: 50%;
  top: -50%;
  left: -25%;
  transform: translate3d(0, 75%, 0);
`

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const ButtonTextInner = styled(ButtonText)<{ $dot?: ButtonProps['dot'] }>`
  ${(props) =>
    props.$dot &&
    css`
      &::after {
        --size: 0.8rem;
        content: '';
        width: var(--size);
        height: var(--size);
        min-width: var(--size);
        min-height: var(--size);
        background-color: var(--btn-color);
        border-radius: 100%;
        margin-left: 1.6rem;
      }
    `}
`

const Element = styled.button`
  &.is-red-theme {
    --btn-color: hsla(0, 0%, 100%);
    --btn-color-hover: var(--brand-red);
    --btn-bg: var(--brand-red);
    --btn-bg-hover: hsla(0, 0%, 100%);
    --btn-border: var(--brand-red);
    --btn-border-hover: var(--brand-red);
  }

  &.is-red-outline-theme {
    --btn-bg: tranparent;
    --btn-bg-hover: var(--brand-red);
    --btn-color: var(--brand-red);
    --btn-color-hover: hsla(0, 0%, 100%);
    --btn-border: var(--brand-red);
    --btn-border-hover: var(--brand-red);
  }

  &.is-white-theme {
    --btn-color: var(--brand-red);
    --btn-dot: var(--brand-red);
    --btn-bg: hsla(0, 0%, 100%);
    --btn-border: hsla(0, 0%, 100%);

    --btn-color: var(--brand-red);
    --btn-color-hover: hsla(0, 0%, 100%);
    --btn-bg: hsla(0, 0%, 100%);
    --btn-bg-hover: var(--brand-red);
    --btn-border: hsla(0, 0%, 100%);
    --btn-border-hover: var(--brand-red);
  }

  &.is-white-outline-theme {
    --btn-color: hsla(0, 0%, 100%);
    --btn-dot: hsla(0, 0%, 100%);
    --btn-bg: transparent;
    --btn-border: hsla(0, 0%, 100%);

    --btn-color-hover: var(--brand-red);
    --btn-dot-hover: var(--brand-red);
    --btn-bg-hover: hsla(0, 0%, 100%);
    --btn-border-hover: hsla(0, 0%, 100%);
  }

  &.is-gray-to-red-outline-theme {
    --btn-color: var(--gray-1);
    --btn-dot: var(--gray-1);
    --btn-bg: transparent;
    --btn-border: var(--gray-3);

    --btn-color-hover: hsla(0, 0%, 100%);
    --btn-dot-hover: hsla(0, 0%, 100%);
    --btn-bg-hover: var(--brand-red);
    --btn-border-hover: var(--brand-red);
  }

  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  appearance: none;
  overflow: hidden;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-decoration: none;
  text-align: left;
  padding: 2.2rem 4rem;
  min-height: 6.6rem;
  min-width: 14rem;
  background-color: var(--btn-bg, transparent);
  border: 0.2rem solid var(--btn-border, currentColor);
  color: var(--btn-color, black);
  border-radius: 40px;
  transition: border-color 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  will-change: transform;

  ${up('sm')} {
    white-space: nowrap;
    text-align: center;
  }

  &:hover,
  &.is-hover {
    outline: none;
    border-color: var(--btn-border-hover);
    color: var(--btn-color-hover);

    ${ButtonTextInner} {
      &::after {
        background-color: var(--btn-color-hover);
      }
    }
  }
`

const leave = (filler: gsap.TweenTarget, textInner: gsap.TweenTarget) => {
  gsap
    .timeline()
    .to(filler, {
      duration: 0.4,
      ease: 'Power3.easeOut',
      y: '-75%',
    })
    .to(
      textInner,
      {
        duration: 0.1,
        ease: 'Power3.easeOut',
        opacity: 0,
        y: '10%',
      },
      0,
    )
    .to(
      textInner,
      {
        duration: 0.25,
        ease: 'Power3.easeOut',
        opacity: 1,
        startAt: { y: '-30%', opacity: 1 },
        y: '0%',
      },
      0.1,
    )
}

const enter = (filler: gsap.TweenTarget, textInner: gsap.TweenTarget) => {
  gsap
    .timeline()
    .to(filler, {
      duration: 0.5,
      ease: 'Power3.easeOut',
      startAt: { y: '75%' },
      y: '0%',
    })
    .to(
      textInner,
      {
        duration: 0.1,
        ease: 'Power3.easeOut',
        opacity: 0,
        y: '-10%',
      },
      0,
    )
    .to(
      textInner,
      {
        duration: 0.25,
        ease: 'Power3.easeOut',
        opacity: 1,
        startAt: { y: '30%', opacity: 1 },
        y: '0%',
      },
      0.1,
    )
}

// Fix any
const Button: React.FC<any> = ({ text, theme, dot, forceHover, ...props }) => {
  const [hover, setHover] = useState(false)
  const buttonTextInnerRef = useRef<HTMLSpanElement>(null)
  const buttonFillerRef = useRef<HTMLDivElement>(null)
  const active = hover || forceHover

  useEffect(() => {
    const buttonTextInner = buttonTextInnerRef.current as gsap.TweenTarget
    const buttonFiller = buttonFillerRef.current as gsap.TweenTarget

    if (active) {
      enter(buttonFiller, buttonTextInner)
    } else {
      leave(buttonFiller, buttonTextInner)
    }

    return () => {
      gsap.killTweensOf(buttonFiller)
      gsap.killTweensOf(buttonTextInner)
    }
  }, [active])

  return (
    <Element
      type="button"
      {...props}
      className={cx({ [`is-${theme}-theme`]: theme, 'is-hover': active })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ButtonFiller ref={buttonFillerRef} />
      <ButtonText>
        <ButtonTextInner
          $dot={dot}
          ref={buttonTextInnerRef}
          className="js-text"
        >
          {text}
        </ButtonTextInner>
      </ButtonText>
    </Element>
  )
}

Button.defaultProps = {
  theme: 'red-outline',
  dot: true,
  forceHover: false,
}

export default Button
