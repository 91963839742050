import { useEffect, useState } from 'react'
import Head from 'next/head'
import { CaravaggioProvider } from 'caravaggio-react'
import { useRouter, Router } from 'next/router'
import { AppProps } from 'next/dist/next-server/lib/router/router'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import NProgress from 'nprogress'
import * as gtag from '@utils/gtag'
import isTouchEnabled from '@utils/isTouchEnabled'
import Default from '@components/layouts/Default'
import CookiesNotification from '@components/CookiesNotification'
import { FontFace } from '@components/fonts'
import Cursor from '@components/Cursor'
import { Preloader } from '@components/Preloader'
import GlobalStyles from '@styles/GlobalStyles'
import theme from '@theme/theme'

import 'nprogress/nprogress.css'
import 'keen-slider/keen-slider.min.css'
import 'swiper/swiper-bundle.css'

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  // TODO: fix any
  const Layout = (Component as any).Layout || Default
  const router = useRouter()
  const [showCursor, setShowCursor] = useState(false)

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => router.events.off('routeChangeComplete', handleRouteChange)
  }, [router.events])

  useEffect(() => {
    setShowCursor(!isTouchEnabled())
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <title>Empressia - Web and Mobile Development Company</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      {/* Styles */}
      <FontFace />
      <Normalize />
      <GlobalStyles />

      {/* Preloader for first page load */}
      <Preloader />

      {/* Page content */}
      <Layout>
        <CaravaggioProvider url="/api/assets">
          <Component {...pageProps} />
        </CaravaggioProvider>
        <CookiesNotification />
        {showCursor && <Cursor />}
      </Layout>
    </ThemeProvider>
  )
}

export default MyApp
