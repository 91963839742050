/**
 * Set cookie
 *
 * @param name - cookie name
 * @param value - cookie value
 * @param days - days to expires
 */
export const set = (name: string, value: string | number, days: number) => {
  let expires = ''

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }

  document.cookie = `${name}=${value || ''}${expires}; path=/`
}

/**
 * Get cookie
 *
 * @param name cookie name
 */
export const get = (name: string) => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }

  return null
}
