import { createGlobalStyle } from 'styled-components'
import variables from './variables'
import { margin, text, display } from './utilities'
import nprogress from './vendor/nprogress'

const GlobalStyles = createGlobalStyle`
  ${variables}

  * {
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  html {
    font-size: 62.5%; // 1rem === 10px
  }

  body {
    font-family: 'Campton';
    font-size: 1.6rem;
    font-weight: 400;
    color: hsl(0, 0%, 0%);

    &.is-fixed {
      overflow: hidden;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img,
  svg {
    max-width: 100%;
  }

  img {
    display: inline-block;
    height: auto;
  }

  strong {
    font-weight: 500;
  }

  hr {
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
  }

  /* UTILITIES */
  ${margin}
  ${text}
  ${display}

  /* VENDORS */
  ${nprogress}
`

export default GlobalStyles
