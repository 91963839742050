export default {
  breakpoints: {
    xs: '300px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1300px',
    xxl: '1600px',
  },
}
