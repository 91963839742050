import styled, { css } from 'styled-components'

interface LinkProps {
  size?: 'neutral' | 'big'
}

const sizes = {
  neutral: css``,
  big: css`
    font-size: 2.4rem;
    line-height: 4.8rem;
  `,
}

const Link = styled.a<LinkProps>`
  position: relative;
  display: inline-block;
  color: var(--link-color, inherit);
  cursor: pointer;
  ${(props) => sizes[props.size || 'neutral']}

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 0.1rem;
    bottom: 0;
    left: 0;
    background-color: var(--link-underline-color, currentColor);
    opacity: 0.1;
    transition: opacity 0.2s ease;
  }
`

Link.defaultProps = {
  size: 'neutral',
}

export default Link
