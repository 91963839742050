import Head from 'next/head'
import { CSSProperties } from 'styled-components'

export const Style = () => (
  <Head>
    <style>{`
        @keyframes preloader1 {
          5% {
            width: 20%;
          }
          10% {
            transform: scale(0);
            width: 20%;
          }
          25% {
            transform: scale(1.25);
            width: 20%;
          }
          30% {
            transform: scale(0.85);
            width: 20%;
          }
          35% {
            transform: scale(1);
            width: 20%;
          }
          100% {
            width: 100%;
          }
        }

        @keyframes preloader2 {
          0% {
            width: 0;
          }
          15% {
            width: 0;
          }
          60% {
            width: 0;
          }
          100% {
            width: 65%;
          }
        }

        @keyframes preloader3 {
          0% {
            width: 0;
          }
          15% {
            width: 0;
          }
          70% {
            width: 0;
          }
          100% {
            width: 70%;
          }
        }

        @keyframes preloader4 {
          0% {
            transform: scale(0);
          }
          5% {
            transform: scale(0);
          }
          20% {
            transform: scale(1.25);
          }
          25% {
            transform: scale(0.85);
          }
          30% {
            transform: scale(1);
          }
        }

        .preloader-container .preloader span {
          position: absolute;
          left: 0;
          border-radius: 50px;
          height: 20%;
        }

        .preloader-container .preloader span:nth-child(1) {
          background: #0c226e;
          top: 0;
          animation-name: preloader1;
          animation-duration: 2s;
          animation-fill-mode: both;
          animation-iteration-count: infinite;
          animation-delay: 400ms;
        }

        .preloader-container .preloader span:nth-child(2) {
          background: #97e6f5;
          transform: translate(0, -50%);
          top: 50%;
          width: 65%;
          animation-name: preloader2;
          animation-duration: 2s;
          animation-fill-mode: both;
          animation-iteration-count: infinite;
          animation-delay: 400ms;
        }

        .preloader-container .preloader span:nth-child(3) {
          background: #0c226e;
          bottom: 0;
          left: 30%;
          width: 70%;
          animation-name: preloader3;
          animation-duration: 2s;
          animation-fill-mode: both;
          animation-iteration-count: infinite;
          animation-delay: 400ms;
        }

        .preloader-container .preloader span:nth-child(4) {
          background: #ec3a47;
          bottom: 0;
          width: 20%;
          animation-name: preloader4;
          animation-duration: 2s;
          animation-fill-mode: both;
          animation-iteration-count: infinite;
          animation-delay: 400ms;
        }
      `}</style>
  </Head>
)

export const containerStyles = {
  overflow: 'hidden',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  width: '100vw',
  height: 'calc(100vh)',
  zIndex: 100,
} as CSSProperties

export const backgroundStyles = {
  position: 'absolute',
  width: 'inherit',
  height: 'inherit',
  top: 'inherit',
  left: 'inherit',
  background: 'white',
  borderTop: '4px solid var(--brand-red)',
} as CSSProperties
