import Link from 'next/link'
import styled from 'styled-components'
import { ILink } from 'types/types'

const StyledLink = styled.a`
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    height: 0.1rem;
    width: 100%;
    background: #ffffff;
    top: calc(100% + 0.4rem);
    left: 0;
  }
`

const CustomLink = ({ href, title, target }: ILink) => (
  <Link href={href}>
    <StyledLink href={href} target={target}>
      {title}
    </StyledLink>
  </Link>
)

export default CustomLink
