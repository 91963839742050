/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-multi-assign */
import lerp from 'lerpjs'

export enum APPERANCE {
  'TEXT' = 'TEXT',
  'DEFAULT' = 'DEFAULT',
}

export default class Controller {
  private element: HTMLElement

  private bounds: DOMRect

  private renderedStyles: any

  constructor(element: HTMLElement) {
    this.element = element
    this.element.style.opacity = '0'

    this.bounds = this.element.getBoundingClientRect()

    this.renderedStyles = {
      tx: { previous: 0, current: 0, amt: 0.15 },
      ty: { previous: 0, current: 0, amt: 0.15 },
      scale: { previous: 0.16, current: 0.16, amt: 0.15 },
      opacity: { previous: 1, current: 1, amt: 0.15 },
    }

    const onMouseMoveEv = () => {
      this.renderedStyles.tx.previous = this.renderedStyles.tx.current =
        window.currentMousePosition.x - this.bounds.width / 2
      this.renderedStyles.ty.previous = this.renderedStyles.ty.previous =
        window.currentMousePosition.y - this.bounds.height / 2

      requestAnimationFrame(() => this.render())
      window.removeEventListener('mousemove', onMouseMoveEv)
    }

    window.addEventListener('mousemove', onMouseMoveEv)
  }

  render() {
    this.renderedStyles.tx.current =
      window.currentMousePosition.x - this.bounds.width / 2
    this.renderedStyles.ty.current =
      window.currentMousePosition.y - this.bounds.height / 2

    for (const key in this.renderedStyles) {
      this.renderedStyles[key].previous = lerp(
        this.renderedStyles[key].previous,
        this.renderedStyles[key].current,
        this.renderedStyles[key].amt,
      )
    }

    this.element.style.transform = `translateX(${this.renderedStyles.tx.previous}px) translateY(${this.renderedStyles.ty.previous}px) scale(${this.renderedStyles.scale.previous})`
    this.element.style.opacity = this.renderedStyles.opacity.previous

    requestAnimationFrame(() => this.render())
  }

  public setApperance(apperance: APPERANCE) {
    switch (apperance) {
      case 'TEXT':
        this.renderedStyles.scale.current = 1
        break
      case 'DEFAULT':
      default:
        this.renderedStyles.scale.current = 0.16
    }
  }

  public destroy() {}
}
