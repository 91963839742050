import { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import cx from 'classnames'
import isNil from 'lodash/isNil'
import pagesTree from '@data/pagesTree'
import Button from '@components/Button'
import MobileMenu from './MobileMenu'
import Brand from './Brand'
import {
  MobileMenuToggle,
  MenuItem,
  NavInner,
  NavGlossy,
  Nav,
  NavContainer,
} from './Navbar.style'

interface NavbarProps {
  staticLinks?: boolean
  theme?: string
}

const Navbar: React.FC<NavbarProps> = ({ staticLinks, theme }) => {
  const router = useRouter()
  const navRef = useRef<HTMLDivElement>(null)
  const [activeHover, setActiveHover] = useState<string | null>(null)
  const [mobileMenuActive, setMobileMenuActive] = useState(false)
  const [navbarHeight, setNavbarHeight] = useState(0)
  const [slim, setSlim] = useState(false)

  const isHomepagePath = router.pathname === '/'

  useEffect(() => {
    const handleResize = () => {
      const navbarRef = navRef.current as HTMLDivElement
      setNavbarHeight(navbarRef.getBoundingClientRect().height)
      document.body.style.setProperty('--navbar-height', `${navbarHeight}px`)
    }

    const handleScroll = () => {
      setSlim(window.pageYOffset > 0)
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('load', () => {
      handleResize()
      handleScroll()
    })

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleClick = () => {
    setMobileMenuActive((p) => !p)
  }

  const handeMouseEnter = (href: string) => {
    setActiveHover(href)
  }

  const handeMouseLeave = () => {
    setActiveHover(null)
  }

  const getLink = (item: any) =>
    item.props.highlight ? (
      <Button
        theme={!slim && theme === 'white' ? 'white' : 'red-outline'}
        text="Estimate project"
      />
    ) : (
      <>
        <span>
          <span className="text">{item.title}</span>
          <span className="dot">.</span>
        </span>
        <span>
          <span className="text">{item.title}</span>
          <span className="dot">.</span>
        </span>
      </>
    )

  return (
    <NavContainer $slim={slim}>
      <Nav $theme={!slim ? theme : ''} ref={navRef}>
        <NavGlossy>
          <NavInner as="nav">
            {staticLinks ? (
              <Brand href="/" />
            ) : (
              <Link href="/" passHref>
                <Brand />
              </Link>
            )}

            <ul>
              {pagesTree.map((item) => (
                <MenuItem
                  onMouseEnter={() => handeMouseEnter(item.href)}
                  onMouseLeave={handeMouseLeave}
                  key={item.href}
                  $highlight={isHomepagePath}
                  $active={router.pathname === item.href}
                  $dimmed={
                    isHomepagePath &&
                    !isNil(activeHover) &&
                    activeHover !== item.href
                  }
                >
                  {staticLinks ? (
                    <a
                      href={item.href}
                      className={cx('d-inline-block', {
                        link: !item.props.highlight,
                      })}
                    >
                      {getLink(item)}
                    </a>
                  ) : (
                    <Link href={item.href} passHref>
                      <a
                        className={cx('d-inline-block', {
                          link: !item.props.highlight,
                        })}
                      >
                        {getLink(item)}
                      </a>
                    </Link>
                  )}
                </MenuItem>
              ))}
            </ul>
            <MobileMenuToggle
              className="mobile-menu-toggle"
              type="button"
              onClick={handleClick}
            >
              <img
                src={mobileMenuActive ? '/icons/close.svg' : '/icons/menu.svg'}
                alt=""
              />
            </MobileMenuToggle>
          </NavInner>
        </NavGlossy>
      </Nav>

      {mobileMenuActive && (
        <MobileMenu setMobileMenuActive={setMobileMenuActive} />
      )}
    </NavContainer>
  )
}

Navbar.defaultProps = {
  staticLinks: false,
  theme: 'default',
}

export default Navbar
