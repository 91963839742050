import { useEffect, useRef } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Container from '@styles/Container'
import CustomLink from '@components/CustomLink'
import { H3 } from '@components/Heading'
import { windowLoadWithTimetout } from '@utils/timeout'
import MenuItem from './MenuItem'

interface Link {
  title: string
  href: string
  target?: string
}

const menuLinks: Link[] = [
  {
    title: 'About us',
    href: '/about',
  },
  {
    title: 'What we do',
    href: '/what-we-do',
  },
  {
    title: 'Our work',
    href: '/work',
  },
  {
    title: 'Process',
    href: '/process',
  },
  {
    title: 'Contact us',
    href: '/contact',
  },
  {
    title: 'Estimate project',
    href: '/estimate-project',
  },
]

const externalLinks: Link[] = [
  {
    title: 'Dribbble',
    href: 'https://dribbble.com/empressia',
    target: '_blank',
  },
  {
    title: 'Facebook',
    href: 'https://pl-pl.facebook.com/empressiapl',
    target: '_blank',
  },
  {
    title: 'Clutch',
    href: 'https://clutch.co/profile/empressia',
    target: '_blank',
  },
]

const Root = styled.footer`
  --text-color: #fff;
  position: relative;
  color: #fff;
  padding: 18rem 0 9.6rem;
  background: #000;
  overflow: hidden;

  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%);
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 0%, 1) 0%,
      hsla(0, 0%, 0%, 0) 18rem
    );
  }

  ${down('lg')} {
    padding: 6rem 0 3rem;

    &::after {
      background: linear-gradient(
        180deg,
        hsla(0, 0%, 0%, 1) 0%,
        hsla(0, 0%, 0%, 0) 6rem
      );
    }
  }
`

const StyledMenuContainer = styled.div`
  display: flex;

  ${down('md')} {
    margin-top: 3.2rem;
  }

  > *:not(:last-child) {
    margin-right: 6.4rem;
  }
`

const Title = styled(H3)`
  margin: 0;

  &,
  a {
    line-height: 8rem;

    ${down('lg')} {
      font-size: 4.8rem;
      line-height: 5rem;
    }

    ${down('md')} {
      font-size: 3.6rem;
    }
  }

  a {
    display: inline-block;
    font-weight: 300;
    position: relative;
    margin-top: 0.3rem;

    &::after {
      content: '';
      position: absolute;
      background: #505050;
      height: 0.1rem;
      width: 100%;
      bottom: -0.3rem;
      left: 0;
    }
  }
`

const StyledHeadingMenusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 14.8rem;

  ${down('lg')} {
    margin: 3.2rem 0 4.8rem;
  }

  ${down('md')} {
    flex-direction: column;
  }

  h2 {
    max-width: 75rem;
    margin-bottom: 3.6rem;
  }
`

const StyledCopyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #5e5e61;
  letter-spacing: 0.02rem;
  font-size: 1.4rem;
  line-height: 3.2rem;
  margin-top: 3.2rem;
  font-family: 'Neue Montreal';
  flex-wrap: wrap;

  ${down('md')} {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 3rem;
  }
`

const StyledMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  letter-spacing: -0.05rem;
  font-weight: 300;
  font-size: 2.4rem;

  li:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`

const Footer: React.FC = () => {
  const footerRef = useRef<HTMLElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current as HTMLDivElement

    gsap.registerPlugin(ScrollTrigger)
    gsap.set(containerRef.current, {
      autoAlpha: 0,
      yPercent: -80,
    })

    windowLoadWithTimetout(() => {
      gsap.to(container, {
        scrollTrigger: {
          trigger: footerRef.current as Element,
          start: 'top bottom',
          end: () =>
            container.getBoundingClientRect().height > window.innerHeight
              ? `+=${window.innerHeight - 80}`
              : 'bottom bottom',
          scrub: true,
        },
        autoAlpha: 1,
        yPercent: 0,
      })
    })
  })

  return (
    <Root ref={footerRef}>
      <Container ref={containerRef}>
        <StyledHeadingMenusContainer>
          <Title>
            Have an awesome idea?
            <br />
            <Link href="/contact">
              <a>we can make it happen</a>
            </Link>
          </Title>
          <StyledMenuContainer>
            <StyledMenu>
              {menuLinks.map((link) => (
                <MenuItem
                  key={link.href}
                  link={link.href}
                  target={link.target}
                  title={link.title}
                />
              ))}
            </StyledMenu>

            <StyledMenu>
              {externalLinks.map((link) => (
                <MenuItem
                  key={link.href}
                  link={link.href}
                  target={link.target}
                  title={link.title}
                />
              ))}
            </StyledMenu>
          </StyledMenuContainer>
        </StyledHeadingMenusContainer>
        <div>
          <CustomLink
            href="mailto:hello@empressia.co"
            title="hello@empressia.co"
          />
          <StyledCopyContainer>
            <address>
              Skalna 20
              <br /> 60-113 Poznań , Poland
            </address>
            <span>
              Empressia &copy;{new Date().getFullYear()} — All rights reserved.
            </span>
          </StyledCopyContainer>
        </div>
      </Container>
    </Root>
  )
}

export default Footer
