import { css } from 'styled-components'

export const backgroundColor = css`
  background: hsla(0, 0%, 100%);

  @supports (backdrop-filter: saturate(180%) blur(0.8rem)) {
    background: hsla(0, 0%, 100%, 0.95);
  }
`

export const glossy = css`
  backdrop-filter: saturate(180%) blur(0.8rem);
`
