import { useState, useEffect, ComponentType } from 'react'
import dynamic from 'next/dynamic'
import isNil from 'lodash/isNil'
import { get as getCookie } from '@utils/cookie'
import { NotificationProps } from './Notification'

const Manager: React.FC = () => {
  const [visible, setVisible] = useState(true)
  const [Content, setContent] = useState<ComponentType<
    NotificationProps
  > | null>(null)

  useEffect(() => {
    const accept = getCookie('accept-cookies')
    if (isNil(accept)) {
      setContent(dynamic(() => import('./Notification')))
    }
  }, [])

  const handleHide = () => {
    setVisible(false)
  }

  return visible && !isNil(Content) ? <Content onHide={handleHide} /> : null
}

export default Manager
