import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import isNil from 'lodash/isNil'
import Controller, { APPERANCE } from './Controller'
import getMousePosition from './getMousePosition'
import { emitter, EventName } from './events'

const Layout = styled.div<{ $expand: boolean }>`
  /* Apperance */
  width: 11.4rem;
  height: 11.4rem;
  border: 0.2rem solid var(--brand-red);
  color: var(--brand-red);
  border-radius: 100%;
  background: var(--brand-red);
  mix-blend-mode: multiply;
  z-index: 90;
  transition: background 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);

  ${(props) =>
    props.$expand &&
    css`
      background: none;
      mix-blend-mode: unset;
    `}

  /* Initial position */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);

  /* Align text to center */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* Disable interactions */
  pointer-events: none;
  user-select: none;
`

const Text = styled.span<{ $visible: boolean }>`
  transition: 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  opacity: 0;
  transform: scale(0);

  ${(props) =>
    props.$visible &&
    css`
      opacity: 1;
      transform: none;
    `}
`

const Cursor: React.FC = () => {
  const cursorRef = useRef<HTMLDivElement>(null)
  const [text, setText] = useState('')
  const [controller, setController] = useState<Controller>()

  useEffect(() => {
    if (!isNil(controller)) {
      controller.setApperance(
        text.length > 0 ? APPERANCE.TEXT : APPERANCE.DEFAULT,
      )
    }
  }, [text])

  useEffect(() => {
    const cursor = cursorRef.current as HTMLDivElement
    setController(new Controller(cursor))

    emitter.on(EventName.SET_TEXT, (data) => {
      setText(data?.text || '')
    })

    return () => {
      controller?.destroy()
      emitter.all.clear()
      setController(undefined)
    }
  }, [])

  useEffect(() => {
    window.currentMousePosition = { x: 0, y: 0 }

    const callback = (event: MouseEvent) => {
      window.currentMousePosition = getMousePosition(event)
    }

    window.addEventListener('mousemove', callback)
    return () => window.removeEventListener('mousemove', callback)
  }, [])

  return (
    <Layout $expand={!!text} ref={cursorRef}>
      <Text $visible={!!text}>{text}</Text>
    </Layout>
  )
}

export default Cursor
