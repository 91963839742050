// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window.gtag('config', process.env.GA_TRACKING_ID as string, {
    page_path: url,
  })
}

// TODO: fix any
interface EventProps {
  action: string
  category: any
  label: any
  value: any
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: EventProps) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  })
}
