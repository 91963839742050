import { up } from 'styled-breakpoints'
import styled from 'styled-components'

const Container = styled.div`
  max-width: 1380px;
  padding: 0 3.2rem;
  margin: 0 auto;

  ${up('lg')} {
    padding: 0 2rem;
  }
`

export default Container
