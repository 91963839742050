import { forwardRef } from 'react'
import styled from 'styled-components'

const Logo = styled.a`
  display: block;
  max-width: 16.2rem;
  width: 100%;
  cursor: pointer;

  @media (min-width: 1099px) {
    max-width: 24.4rem;
  }

  img {
    display: inherit;
  }
`

// TODO: fix any
const Brand: any = forwardRef((props, ref) => (
  <Logo ref={ref as any} {...props}>
    <img src="/icons/logo.svg" alt="Empressia" />
  </Logo>
))

Brand.displayName = 'Brand'

export default Brand
