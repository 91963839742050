import styled, { css } from 'styled-components'

interface TextProps {
  size?: 'small' | 'normal' | 'big' | 'large'
  color?: 'gray' | 'black'
  weight?: number
}

const sizes = {
  small: css`
    font-size: 1.4rem;
    line-height: 2.8rem;
  `,
  normal: css`
    font-size: 1.6rem;
    line-height: 3.2rem;
    letter-spacing: 0.02rem;
  `,
  big: css`
    font-size: 2rem;
    line-height: 4rem;
    letter-spacing: 0.02rem;
  `,
  large: css`
    font-size: 2.4rem;
    line-height: 4.8rem;
  `,
}

const Link = styled.p<TextProps>`
  font-family: 'Neue Montreal';
  ${(props) => sizes[props.size || 'normal']}
  color: ${(props) =>
    props.color === 'black' ? 'var(--text-color)' : 'var(--gray-5)'};
  font-weight: ${(props) => props.weight || 400};
`

Link.defaultProps = {
  size: 'normal',
  color: 'black',
}

export default Link
