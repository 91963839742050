import { css } from 'styled-components'

const variables = css`
  /* TODO: Apply <ThemeProvider> insteand CSS Variables */
  :root {
    --text-color: #000000;
    --bg-color: #ffffff;

    --brand-red: #ec3a47;
    --brand-blue: #97e6f5;
    --brand-dark-blue: #0c226e;

    --gray-1: #cccccc;
    --gray-2: #ec3a47;
    --gray-3: #ebebeb;
    --gray-4: #dcdcdc;
    --gray-5: #616161;

    --dark-1: #5e5e61;
    --dark-2: #505050;

    /* Grid */
    --gap: 3.2rem;
    --columns: 12;
  }
`

export default variables
