import styled, { css } from 'styled-components'
import Container from '@components/styled/Container'
import { backgroundColor, glossy } from './styles'

export const MobileMenuToggle = styled.button`
  display: none;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  border: 0;
  margin: 0 -0.6rem 0 0;
  background: none;

  /* "Hamburger" icon */
  img {
    display: block;
    margin: 0 auto;
    vertical-align: middle;
  }

  @media (max-width: 1100px) {
    display: block;
  }
`

export const MenuItem = styled.li<{
  $active: boolean
  $highlight: boolean
  $dimmed: boolean
}>`
  &:last-child {
    margin-left: 2rem;
  }

  .text,
  .dot {
    transition: color 0.2s cubic-bezier(0.17, 0.84, 0.44, 1);
  }

  .link {
    display: inline-block;
    font-weight: 500;
    position: relative;
    color: #e3e3e3;
    padding: 1rem 2rem;

    > span {
      display: inherit;
      transition: 0.2s cubic-bezier(0.17, 0.84, 0.44, 1);
      transition-property: transform, opacity;

      &:nth-child(2) {
        position: absolute;
        left: 2rem;
        transform: translateY(120%);
        opacity: 0;
        pointer-events: none;
        color: var(--brand-dark-blue);
      }
    }
  }

  &:hover {
    .link {
      > span {
        &:nth-child(1) {
          transform: translateY(-120%);
          opacity: 0;
        }

        &:nth-child(2) {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  ${(props) =>
    props.$highlight &&
    css`
      .link {
        color: var(--brand-dark-blue);
      }
    `}

  ${(props) =>
    props.$active &&
    css`
      .link {
        color: var(--brand-dark-blue);
      }

      .dot {
        color: var(--brand-red);
      }
    `}

  ${(props) =>
    props.$dimmed &&
    css`
      .link,
      .dot {
        color: #e3e3e3;
      }
    `}
`

export const NavGlossy = styled.div`
  ${glossy}
  height: 100%;
`

export const NavInner = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

export const Nav = styled.div<{ $theme?: string }>`
  position: fixed;
  width: 100%;
  height: var(--navbar-height);
  top: 0;
  left: 0;
  z-index: 90;
  transition: 0.4s cubic-bezier(0.17, 0.84, 0.44, 1);
  transition-property: box-shadow, height, transform, background-color;
  ${backgroundColor}

  ${(props) =>
    props.$theme === 'white' &&
    css`
      background: none !important;

      ${NavGlossy} {
        backdrop-filter: none !important;
      }

      img {
        filter: brightness(0) invert(1);
      }

      .link > span {
        color: white !important;
      }
    `}

  ul {
    display: inherit;
    align-items: inherit;
    list-style: none;
    margin: 0;
  }

  @media (max-width: 1100px) {
    height: 8rem;

    ul {
      display: none;
    }
  }
`

export const NavContainer = styled.div<{
  $slim: boolean
}>`
  --navbar-height: 8rem;
  position: relative;
  height: var(--navbar-height);
  margin: 0 0 8rem;

  @media (min-width: 1099px) {
    --navbar-height: 18rem;
  }

  ${(props) =>
    props.$slim &&
    css`
      ${Nav} {
        --navbar-height: 10rem;
        box-shadow: 0 0px 1px rgba(0, 0, 0, 0.038),
          0 0px 2.9px rgba(0, 0, 0, 0.055), 0 0px 6.9px rgba(0, 0, 0, 0.072),
          0 0px 23px rgba(0, 0, 0, 0.11);
      }
    `}
`
