import { css } from 'styled-components'
import { up } from 'styled-breakpoints'

const margin = css`
  .my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mt {
    &-0 {
      margin-top: 0;
    }

    &-80 {
      margin-top: 8rem;
    }

    ${up('md')} {
      &-md {
        &-128 {
          margin-top: 12.8rem;
        }
      }
    }
  }

  .mb {
    &-8 {
      margin-bottom: 0.8rem;
    }

    &-24 {
      margin-bottom: 2.4rem;
    }

    &-16 {
      margin-bottom: 1.6rem;
    }

    &-32 {
      margin-bottom: 3.2rem;
    }

    &-48 {
      margin-bottom: 4.8rem;
    }

    &-80 {
      margin-bottom: 8rem;
    }

    ${up('md')} {
      &-md {
        &-0 {
          margin-bottom: 0;
        }

        &-112 {
          margin-bottom: 11.2rem;
        }

        &-128 {
          margin-bottom: 12.8rem;
        }

        &-160 {
          margin-bottom: 16rem;
        }

        &-200 {
          margin-bottom: 20rem;
        }
      }
    }
  }
`

export default margin
