import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

const global = css<{ weight?: number | string; color?: 'black' | 'gray' }>`
  font-family: Campton;
  font-weight: ${(props) => props.weight || 400};
  color: ${(props) =>
    props.color === 'gray' ? 'var(--gray-5)' : 'var(--text-color)'};
`

export const H1 = styled.h1`
  font-size: 5.6rem;
  line-height: 6.8rem;
  letter-spacing: -0.2rem;
  ${global}

  ${up('md')} {
    font-size: 8rem;
    line-height: 8.8rem;
  }

  ${up('lg')} {
    font-size: 11.2rem;
    line-height: 14.4rem;
  }
`

export const H2 = styled.h2`
  font-size: 4.8rem;
  line-height: 6rem;
  letter-spacing: -0.1rem;
  ${global}

  ${up('md')} {
    font-size: 6.4rem;
    line-height: 8rem;
  }

  ${up('lg')} {
    font-size: 8rem;
    line-height: 8.8rem;
  }
`

export const H3 = styled.h3`
  font-size: 4rem;
  line-height: 4.8rem;
  letter-spacing: -0.05rem;
  margin: 0 0 2.4rem;
  ${global}

  ${up('lg')} {
    font-size: 6.4rem;
    line-height: 8rem;
    letter-spacing: -0.1rem;
  }
`

export const H4 = styled.h4`
  font-size: 3.2rem;
  line-height: 4.2rem;
  letter-spacing: -0.05rem;
  margin: 0 0 2.4rem;
  ${global}

  ${up('lg')} {
    font-size: 4rem;
    line-height: 4.8rem;
  }
`

export const H5 = styled.h5`
  font-size: 2.4rem;
  line-height: 3.2rem;
  letter-spacing: -0.05rem;
  ${global}
`
