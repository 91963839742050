import Footer from '@components/Footer'
import Navbar from '@components/Navbar'
import { AppProps } from 'next/dist/next-server/lib/router/router'

const Default = ({ children }: AppProps) => (
  <main>
    <Navbar />
    {children}
    <Footer />
  </main>
)

export default Default
