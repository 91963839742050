interface PageTreeProps {
  highlight?: boolean
}

interface PageTree {
  title: string
  href: string
  props: PageTreeProps
}

type PagesTree = PageTree[]

const pagesTree: PagesTree = [
  {
    title: 'about',
    href: '/about',
    props: {},
  },
  {
    title: 'what we do',
    href: '/what-we-do',
    props: {},
  },
  {
    title: 'work',
    href: '/work',
    props: {},
  },
  {
    title: 'process',
    href: '/process',
    props: {},
  },
  {
    title: 'contact',
    href: '/contact',
    props: {},
  },
  {
    title: 'estimate project',
    href: '/estimate-project',
    props: { highlight: true },
  },
]

export default pagesTree
