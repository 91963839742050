const FontFace = () => (
  <style global jsx>{`
    @font-face {
      font-family: 'Campton';
      src: url('/fonts/Campton/Campton-ExtraLight.woff2') format('woff2');
      font-weight: 200;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Campton';
      src: url('/fonts/Campton/Campton-ExtraLightItalic.woff2') format('woff2');
      font-weight: 200;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Campton';
      src: url('/fonts/Campton/Campton-BookItalic.woff2') format('woff2');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Campton';
      src: url('/fonts/Campton/Campton-Book.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Campton';
      src: url('/fonts/Campton/Campton-Medium.woff2') format('woff2');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Campton';
      src: url('/fonts/Campton/Campton-MediumItalic.woff2') format('woff2');
      font-weight: 500;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Neue Montreal';
      src: url('/fonts/NeueMontreal/NeueMontreal-Bold.woff2') format('woff2');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Neue Montreal';
      src: url('/fonts/NeueMontreal/NeueMontreal-BoldItalic.woff2')
        format('woff2');
      font-weight: bold;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Neue Montreal';
      src: url('/fonts/NeueMontreal/NeueMontreal-Light.woff2') format('woff2');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Neue Montreal';
      src: url('/fonts/NeueMontreal/NeueMontreal-Italic.woff2') format('woff2');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Neue Montreal';
      src: url('/fonts/NeueMontreal/NeueMontreal-Regular.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  `}</style>
)

export default FontFace
