import { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { emitter, windowLoad } from '@utils/timeout'
import { Style, containerStyles, backgroundStyles } from './Preloader.style'

export const Preloader = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const background = containerRef.current?.querySelector('.background')
    const icon = containerRef.current?.querySelector('.preloader')

    windowLoad(() => {
      setTimeout(() => {
        gsap
          .timeline({
            onComplete: () => {
              setVisible(false)
              emitter.emit('WINDOW_LOAD')
            },
          })
          .to(background as gsap.TweenTarget, {
            yPercent: 100,
            duration: 0.7,
            ease: 'power4.out',
          })
          .to(
            icon as gsap.TweenTarget,
            {
              scale: 0,
              duration: 0.1,
              ease: 'power3.out',
            },
            '<',
          )
      }, 2000)
    })
  }, [])

  return visible ? (
    <>
      <Style />
      <div
        className="preloader-container"
        ref={containerRef}
        style={containerStyles}
      >
        <div style={backgroundStyles} className="background" />

        <div
          style={{
            position: 'relative',
            width: 50,
            height: 50,
          }}
          className="preloader"
        >
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    </>
  ) : null
}
