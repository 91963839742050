// import { useEffect, useRef, useState } from 'react';
import Link from 'next/link'
// import gsap from 'gsap';
import styled from 'styled-components'

interface MenuItemProps {
  title: string
  link: string
  target?: string
}

const Item = styled.a`
  display: inline-block;
  position: relative;
  font-size: 2.4rem;
  letter-spacing: -0.05rem;
  font-weight: 300;

  li:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`

const Text = styled.span`
  display: inline-block;
  position: relative;
`

// const TextOverlay = styled(Text)`
//   position: absolute;
// `

const MenuItem: React.FC<MenuItemProps> = ({ title, link, target }) => {
  // const overlayRef = useRef<HTMLSpanElement>(null);
  // const overlayTextRef = useRef<HTMLSpanElement>(null);
  // const textRef = useRef<HTMLSpanElement>(null);
  // const [hover, setHover] = useState(false);

  // const enter = () => {
  //   gsap
  //     .timeline()
  //     .to(textRef.current, {
  //       startAt: { y: 0, opacity: 1 },
  //       y: '100%',
  //       opacity: 0,
  //       duration: 0.4,
  //       ease: 'Power3.easeOut',
  //     })
  //     .to(
  //       overlayTextRef.current,
  //       {
  //         startAt: { y: '-100%', opacity: 0 },
  //         y: 0,
  //         opacity: 1,
  //         duration: 0.4,
  //         ease: 'Power3.easeOut',
  //       },
  //       '<',
  //     );
  // };

  // const leave = () => {
  //   gsap
  //     .timeline()
  //     .to(textRef.current, {
  //       y: 0,
  //       opacity: 1,
  //       duration: 0.4,
  //       ease: 'Power3.easeOut',
  //     })
  //     .to(
  //       overlayTextRef.current,
  //       {
  //         y: '-100%',
  //         opacity: 0,
  //         duration: 0.4,
  //         ease: 'Power3.easeOut',
  //       },
  //       '<',
  //     );
  // };

  // useEffect(() => {
  //   if (hover) {
  //     enter();
  //   } else {
  //     leave();
  //   }

  //   return () => {
  //     gsap.killTweensOf(overlayRef.current);
  //     gsap.killTweensOf(textRef.current);
  //   };
  // }, [hover]);

  return (
    <li
    // onMouseEnter={() => setHover(true)}
    // onMouseLeave={() => setHover(false)}
    >
      <Link href={link} passHref>
        <Item target={target}>
          {/* <TextOverlay>{title}</TextOverlay> */}
          <Text>{title}</Text>
        </Item>
      </Link>
    </li>
  )
}

export default MenuItem
